import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_ORIGIN;

export const getProjectData = async (projectId, accessToken) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAllDataForSelf = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/user/self`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getProjectRespondentData = async (projectId, respondentId, accessToken) => {
  const config = {
    url: `${apiServerUrl}/project/${projectId}/respondent/${respondentId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getRespondentData = async (respondentId, accessToken) => {
  const config = {
    url: `${apiServerUrl}/respondent/${respondentId}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
   
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAllUsers = async ( accessToken ) => {

  const config = {
    url: `${apiServerUrl}/admin/user`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };

};

export const getOrganizations = async ( accessToken ) => {

  const config = {
    url: `${apiServerUrl}/admin/organization`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`
    }
  }

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error
  };

};


