import React from "react";
import { Link } from "react-router-dom";


const Footer = () => (
  <footer className="bg-light text-center">
    <p>
      Copyright &copy; 2023 Social Venture Partners, LLC. All rights reserved. - <Link to={"/legal"}>Terms of Use</Link> - <Link to={"/legal/privacy"}>Privacy Policy</Link>
    </p>
  </footer>
);

export default Footer;
