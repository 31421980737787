import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap';



const BreadcrumbNav = (options) => (
  <Breadcrumb className="gc-breadcrumb">
    {options.breadcrumbItems.map(function(breadcrumb, idx) {
      if (breadcrumb.url) {
        return (<BreadcrumbItem><Link to={breadcrumb.url}>{breadcrumb.label}</Link></BreadcrumbItem>);
      } else {
        return (<BreadcrumbItem active>{breadcrumb.label}</BreadcrumbItem>);
      }
    })}
  </Breadcrumb>


)

export default BreadcrumbNav