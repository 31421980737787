import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import NotFound from "./NotFound";

export const AuthenticationGuard = ({ component, role }) => {
  
  const { user, isLoading } = useAuth0();
  const [ hasRequiredRole, setHasRequiredRole ] = useState(false);

  useEffect(() => {
    if (!isLoading && user) {
      const userRoles = user['https://gutcheck.svpvc.com/roles'];
      setHasRequiredRole( role && userRoles ? userRoles?.includes(role) : true);
    }
  }, [ isLoading, user, role ]);
  
  if (isLoading) {
    return <div className="page-layout"><Loading /></div>;
  }

  if ( !hasRequiredRole && role ) {
    return <NotFound />;
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <Loading />
      </div>
    ),
  });

  return <Component />;
};
