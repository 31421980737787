import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import { getProjectRespondentData, getRespondentData } from "../../services/gutcheck-api.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const RespondentComponent = () => {
  
  const [state, setState] = useState({
    fetched: false,
    organization: null,
    project: null,
    respondentHtml: "",
    error: null,
    ok: null,
    isPrintView: false
  });

  const [ isPrintView, setisPrintView ] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const { respondentId, projectId } = useParams();

  useEffect(() => {
    let isMounted = true;

    const fetchProjectData = async () => {
      const accessToken = await getAccessTokenSilently();

      const { data, error } = projectId ? await getProjectRespondentData( projectId, respondentId, accessToken) : await getRespondentData(respondentId, accessToken);
    
      if (!isMounted) {
        return;
      }

      if (data) {
        setState({
          ...state,
          fetched: true,
          organization: data.organization,
          project: data.project,
          respondentHtml: { __html: data.html }
        });
      } else if (error) {
        setState({
          ...state,
          fetched: true,
          error: error
        })
      }
    };

    fetchProjectData(); 

    return () => {
      isMounted = false;
    };
  }, []);

  if (!state.fetched) return <Loading />

  if(state.fetched && state.error) return <NotFound/>

  const handleViewSwitchClick = (event) => {


    if (!isPrintView) {
      Array.from(document.getElementsByClassName("sectionContainer")).forEach((element) => {
        const sectionName = element.getAttribute("id");
        const titleDiv = document.createElement("div");
        titleDiv.classList.add("project-section-title");
        titleDiv.innerHTML = sectionName;
        element.prepend(titleDiv);
      });
      
      document.getElementsByClassName("respondent-page")[0].classList.add("print-view");
      document.getElementsByClassName("print-view-toggle")[0].classList.add("depressed");
      setisPrintView(true);

    } else {
      document.getElementsByClassName("respondent-page")[0].classList.remove("print-view");
      Array.from(document.getElementsByClassName("project-section-title")).forEach((element) => {
        element.parentNode.removeChild(element);
      });
      document.getElementsByClassName("print-view-toggle")[0].classList.remove("depressed");
      setisPrintView(false);
    }

  }

  return (
    <div class="respondent-page">
      <div class="project-page-controls">
        <Link to={`/project/${state.project.tag}/responses`} className="back-link" >&lt; Back to list</Link>
        <div onClick={handleViewSwitchClick} className="print-view-toggle icon-button"><FontAwesomeIcon icon="print" /></div>
      </div>
      <div dangerouslySetInnerHTML={state.respondentHtml}></div>  
    </div>
  );
};

export default RespondentComponent;
