import React, { Fragment } from "react";
import { Outlet, NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export const AdminComponent = () => {

  const { user } = useAuth0();

    return (
    <div className="gc_view">
      <div className="admin-header">
        <h2>Admin Dashboard</h2>
        <NavLink to={`/admin/users`}>Users</NavLink>
        <NavLink to={`/admin/organizations`}>Organizations</NavLink>
        <NavLink to={`/admin/projects`}>Projects</NavLink>
        <NavLink to={`/admin/respondents`}>Respondents</NavLink>
      </div>
      <div className="admin-content">
        <Outlet />

      </div>
    </div>
    );
}

export default AdminComponent;