import React from "react";
import { useParams, Outlet, NavLink } from "react-router-dom";


export const LegalComponent = () => {

  return (
    <div class="gc_view">
      <div className="project-header">
        <h2>Legal</h2>

        <NavLink end to={`/legal`}>Terms</NavLink>
        <NavLink to={`/legal/privacy`}>Privacy</NavLink>
      </div>
      <div className="project-content">
        <Outlet />
      </div>
    </div>
  );


}

export default LegalComponent;
