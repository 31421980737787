import React, { Suspense, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllUsers } from "../../services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Table } from "reactstrap";
import Loading from "../../components/Loading";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { AddUserForm } from "./components/form-add-user";


export const AdminUsers = () => {

  //const [users, setUsers] = useState();
  const [ state, setState ] = useState({
    users: null,
    highlightedUser: null
  })
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      //const { oData, oError } = await getOrganizations(accessToken);
      //const { data, error } = await getAllUsers(accessToken);

      const [respUsers] = await Promise.all([
        getAllUsers(accessToken)
      ]);

      if (respUsers) {
        setState({
          ...state,
          users: respUsers.data.users
        });
      }

    };

    fetchData();

    return () => {
    }
  }, []);

  if (!state.users) {
    return <></>
  }

  const handleAddUserClick = async ( event ) => {
    console.log(event);
    event.target.parentElement.parentElement.classList.add("show-form");
    event.preventDefault();
  }

  const handleCallback = async ( user ) => {

    const accessToken = await getAccessTokenSilently();
    const { data, error } = await getAllUsers(accessToken);
    setState({
      ...state,
      highlightedUser: user._id,
      users: data.users
    });

    document.getElementsByClassName("admin-add-object-wrapper")[0].classList.remove("show-form");
  }

  return (
    <Fragment>
      <div className="admin-add-object-wrapper">
        <div className="add-user-toggle">
          <button
            onClick={handleAddUserClick}
          >
            Add User
          </button>
        </div>
        <div className="add-user-form">
          <AddUserForm parentCallback={handleCallback} />
        </div>
      </div>
      <Table responsive hover className="gc-respondent-table"> 
        <thead>
        <tr>
          <th>Email</th>
          <th>Role</th>
          <th>Organizations</th>       
        </tr>
        </thead>
        <tbody>

      {state.users.map((user, idx) => {
        const url = `/admin/user/${user._id}`;
        console.log(user._id + " - " + state.highlightedUser);
        return (
          <tr className={state.highlightedUser && user._id === state.highlightedUser ? "highlighted" : ""}>
            <td><Link to={url}>{user.emailAddress ? user.emailAddress : "no email"}</Link></td>
            <td>{user.role}</td>
            <td>{user.organizations.map( (organization) => { return (<div className="user-org-tag">{organization.name}</div>); })}</td>
          </tr>
        );
      })}
      </tbody>
      </Table>
    </Fragment>
  );

}

export default AdminUsers;