import React, { useState, useEffect, Fragment } from "react";
import { Table } from "reactstrap";
import { useParams, Link, useOutletContext, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProjectData } from "../../services/gutcheck-api.service";

export const ProjectResponses = () => {
  
  const [state, setState] = useState({
    fetched: false,
    organization: null,
    project: null,
    respondents: null,
    error: null,
    ok: null
  });

  const interestFields = [
    {
      key: "demoInterest",
      text: "Demo?"
    },
    {
      key: "betaInterest",
      text: "Beta?"
    },
    {
      key: "investorInterest",
      text: "Invest?"
    },
    {
      key: "advisingInterest",
      text: "Advise?"
    },
    {
      key: "employmentInterest",
      text: "Work?"
    },
    {
      key: "summaryInterest",
      text: "Summary?"
    },
    {
      key: "followupInterest",
      text: "Follow Up?"
    },
  ]


  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [organization, project, respondents] = useOutletContext();
  const projectName = project.name;
  const projectTag = project.tag;

  
  const validInterestFields = respondents[0] && respondents[0].interests ? Object.entries(respondents[0].interests).map( ( interest ) => {  return interest[0]; }): [];

  return (
    <Fragment>
      <Table responsive hover className="gc-respondent-table"> 
        <thead>
        <tr>
          <th className="center"><FontAwesomeIcon icon="film" /></th>
          <th>Name</th>
          <th className="center">Links</th>
          <th>Title</th>
          <th>Company</th>
          <th>Role</th>
          <th className="pill pill-lg">Demand</th>

          {interestFields.map((interestField) => {
            if ( validInterestFields.includes(interestField.key)) {
              return (
                <th className="pill pill-sm">{interestField.text}</th>
              )
            }
          })}      

        </tr>
        </thead>
        <tbody>

      {respondents.map((respondent, idx) => {
        const url = `/project/${projectTag}/responses/${respondent.uuid}`;
        const liClass = respondent.linkedIn ? "" : "hidden";
        return (
          <tr>
            <td className="center">{ respondent.hasVideo && <FontAwesomeIcon icon="film" />}</td>
            <td><Link to={url}>{respondent.name ? respondent.name : "Anonymous"}</Link></td>
            <td className="center"><a href={respondent.linkedIn} target={"_new"} className={liClass}><img className="li-icon" src={'/images/linkedin.png'}/></a></td>
            <td>{respondent.title}</td>
            <td>{respondent.company}</td>
            <td>{respondent.role}</td>
            <td className="pill pill-lg">
              <div className={respondent.demand.replace(/(Yes|No), /g,"").replace(/ /g,"").toLowerCase()}>{respondent.demand.replace(/(Yes|No), /g,"")}</div>
            </td>

            {interestFields.map((interestField) => {
              if (validInterestFields.includes(interestField.key)) {
                return (
                  <td className="pill pill-sm">
                    <div className={respondent.interests[interestField.key] && respondent.interests[interestField.key].toLowerCase().replace(/[^a-z]/g, "")}>{respondent.interests[interestField.key]}</div>
                  </td>
                )
              }
            })}
          </tr>
        );
      })}
      </tbody>
      </Table>
    </Fragment>
  );
};

export default ProjectResponses;
