import React, { Fragment } from "react";

export const AdminIndex = () => {


    return (
      <Fragment>
        Admin Index
      </Fragment>
    );

}

export default AdminIndex;