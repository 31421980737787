import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";


export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/dashboard",
      },
      authorizationParams: {
        prompt: "login",
        screen_hint: "signup",
      },
    });
  };

  return (
        <Link 
        id="qsSignupBtn"
        color="secondary"
        className="button-companion"
        onClick={handleSignUp}>
        Sign Up
      </Link>
  );
};
