import React from "react";
import { Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import EmailVerification from "./components/EmailVerification";
import Home from "./views/Home";
import Dashboard from "./views/Dashboard";
import Profile from "./views/Profile";
import Respondent from "./features/project/project-respondent";
import Project from "./views/Project";
import Legal from "./views/Legal";
import Admin from "./views/Admin";
import Report from "./features/project/project-summary";
import Analysis from "./features/project/project-analysis";
import { useAuth0 } from "@auth0/auth0-react";
import { NotFoundPage } from "./pages/NotFoundPage";
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import { CallbackPage } from "./pages/CallbackPage";
import { PageLayout } from "./components/PageLayout";
import AdminIndex from "./features/admin/admin-index";
import AdminUsers from "./features/admin/admin-users";
import ProjectResponses from "./features/project/project-responses";
import ProjectRespondent from "./features/project/project-respondent";
import ProjectSummary from "./features/project/project-summary";
import ProjectAnalysis from "./features/project/project-analysis";
import ProjectOverview from "./features/project/project-overview";
import ProjectSurvey from "./features/project/project-survey";
import LegalPrivacy from "./features/legal/legal-privacy";
import LegalTerms from "./features/legal/legal-terms";



import "./App.css";
import initFontAwesome from "./utils/initFontAwesome";

export const App = () => {
  initFontAwesome();

  const { user, isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <PageLayout>
        <Loading />
      </PageLayout>
    );
  }

  if (user && !user.email_verified) {
    return (
    <PageLayout>
      <EmailVerification />
    </PageLayout>
    );
  }

  return (
    <PageLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<AuthenticationGuard component={Dashboard} />}/>
            <Route path="/profile" element={<AuthenticationGuard component={Profile} />} />
            <Route path="/project" element={<AuthenticationGuard component={Project} />} >
              <Route path=":projectId" element={<AuthenticationGuard component={ProjectOverview} />} />
              <Route path=":projectId/survey" element={<AuthenticationGuard component={ProjectSurvey} />} />
              <Route path=":projectId/responses" element={<AuthenticationGuard component={ProjectResponses} />} />
              <Route path=":projectId/responses/:respondentId" element={<AuthenticationGuard component={ProjectRespondent} />} />
              <Route path=":projectId/respondent/:respondentId" element={<AuthenticationGuard component={ProjectRespondent} />} />
              <Route path=":projectId/report/analysis" element={<AuthenticationGuard component={ProjectAnalysis} />} />
              <Route path=":projectId/report" element={<AuthenticationGuard component={ProjectSummary} />} />
              <Route path=":projectId/analysis" element={<AuthenticationGuard component={ProjectAnalysis} />} />
            </Route>
            <Route path="/admin" element={<AuthenticationGuard component={Admin} role="admin" />} >
              <Route path="users" element={<AuthenticationGuard component={AdminUsers} role="admin" />} />
            </Route>
            <Route path="/legal" element={<Legal/>}>
              <Route index element={<LegalTerms/>}/>
              <Route path="privacy" element={<LegalPrivacy/>} />
            </Route>
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
    </PageLayout>
  );
};