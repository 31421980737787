import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Container } from "reactstrap";


export const PageLayout = ({ children }) => {
  return (
    <React.Fragment>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          {children}
        </Container>
        <Footer />
      </div>
    </React.Fragment>
  );
};
