import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, ListGroup, ListGroupItem } from "reactstrap";

const ProjectCard = (options) => (
    <Card className="gc-project-cards">
      <CardHeader>{options.organization.name}</CardHeader>
      <ListGroup flush>
        {options.projects.map(project => {

          return (
            <ListGroupItem>
            <div className="left">{project.name}</div>
            <div className="buttons right">
            { project.analysisUrl ? (
                <Button color="primary" tag={Link} to={"/project/" + project.tag + "/analysis"}>
                    View Analysis
                </Button>
                ) : (
                
                <Button className="disabled">
                  View Analysis
                </Button>
                )}
              { project.reportUrl ? (
                <Button color="primary" tag={Link} to={"/project/" + project.tag + "/report"}>
                    View Summary
                </Button>
                ) : (
                
                <Button className="disabled">
                  View Summary
                </Button>
                )}
              <Button color="primary" tag={Link} to={"/project/" + project.tag + "/responses"}>
                View Respondents
              </Button>
            </div>
          </ListGroupItem>
                      )



        })}
      
      </ListGroup>
    </Card>
)

export default ProjectCard