import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_ORIGIN;

export const getOrganizations = async (accessToken) => {

    const config = {
        url: `${apiServerUrl}/admin/organization`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`
        }
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };

};

export const addUser = async (user, accessToken) => {
    console.log(user);
    const config = {
        url: `${apiServerUrl}/admin/user`,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        data: user
    }

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error
    };
}
