import React, { useState, useEffect, Fragment } from "react";
import { Table } from "reactstrap";
import ReactDOM from "react-dom";
import { useParams, Link, useOutletContext } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import { getProjectData } from "../../services/gutcheck-api.service";


export const ProjectSurvey = () => {

  const [state, setState] = useState({
    fetched: false,
    organization: null,
    project: null,
    respondents: null,
    error: null,
    ok: null
  });

  const { getAccessTokenSilently } = useAuth0();

  const [organization, project, respondents] = useOutletContext();

  return (
    <Fragment>

      <iframe
      className="survey-embed"
      src={project.surveyUrl}
      style={{
        width: "1px",
        minWidth: "100%",
        minHeight: "100%"
      }}
      frameBorder="0"     
      allow="autoplay; camera; microphone"
      >

      </iframe>
    </Fragment>
  )
};

export default ProjectSurvey;
