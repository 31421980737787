import React, { useState, useEffect, Fragment } from "react";
import { Table } from "reactstrap";
import ReactDOM from "react-dom";
import { useParams, Link, useOutletContext, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import BreadcrumbNav from "../../components/BreadcrumbNav";
import { getProjectData } from "../../services/gutcheck-api.service";
import { Document, Page, pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export const ProjectAnalysis = () => {

  const [state, setState] = useState({
    fetched: false,
    organization: null,
    project: null,
    respondents: null,
    error: null,
    ok: null
  });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const [organization, project, respondents] = useOutletContext();

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleViewSwitch = () => {
    navigate(`/project/${project.tag}/report`);
  }

  return (
    <Fragment>
      <div className="response-views">
        <div className="response-view-chart" onClick={handleViewSwitch}><FontAwesomeIcon icon="chart-area" /></div>
        <div className="response-view-analysis active"><FontAwesomeIcon icon="file-powerpoint" /></div>
      </div>
      <Document
        file={project.analysisPdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} scale={1.6}/>
      </Document>
      <div className="analysis-pagination">
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <span>
        {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </span>

        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
      </Fragment>
  )
  
  /*
  return (
    <Fragment>
      <div class="pdf-wrapper">
        <object data={samplePdf} type="application/pdf" class="pdf-embed"></object>
      </div>
    </Fragment>
  )

  */
  
};



export default ProjectAnalysis;
