import React, { useState, useEffect, Fragment } from "react";
import { Table } from "reactstrap";
import ReactDOM from "react-dom";
import { useParams, Link, useOutletContext, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import { getProjectData } from "../../services/gutcheck-api.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const ProjectSummary = () => {

  const [state, setState] = useState({
    fetched: false,
    organization: null,
    project: null,
    respondents: null,
    error: null,
    ok: null
  });

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [organization, project, respondents] = useOutletContext();

  const handleViewSwitch = () => {
    navigate(`/project/${project.tag}/report/analysis`);
  }

  return (
    <Fragment>
      {project.analysisPdf ?
        (
          <div className="response-views">
            <div className="response-view-chart active"><FontAwesomeIcon icon="chart-area" /></div>
            <div className="response-view-analysis" onClick={handleViewSwitch}><FontAwesomeIcon icon="file-powerpoint" /></div>
          </div>
        ) : ""}

      <iframe
        src={project.reportUrl}
        style={{
          width: "1px",
          minWidth: "100%",
          minHeight: "100%"
        }}
        frameBorder="0"
      >

      </iframe>
    </Fragment>
  )
};

export default ProjectSummary;
