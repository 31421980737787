import React, { useState, useEffect, Fragment } from "react";
import { Table } from "reactstrap";
import ReactDOM from "react-dom";
import { useParams, Link, useOutletContext } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import NotFound from "../../components/NotFound";
import { getProjectData } from "../../services/gutcheck-api.service";


export const ProjectOverview = () => {

  const [state, setState] = useState({
    fetched: false,
    organization: null,
    project: null,
    respondents: null,
    error: null,
    ok: null
  });

  const { getAccessTokenSilently } = useAuth0();
/*
  const { projectId } = useParams();

  useEffect(() => {
    let isMounted = true;

    const fetchProjectData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getProjectData(projectId, accessToken);

      if (!isMounted) {
        return;
      }

      if (data) {
        setState({
          ...state,
          fetched: true,
          organization: data.organization,
          project: data.project,
          respondents: data.respondents,
          error: error,
          ok: data.ok,
        });
      }
    };

    fetchProjectData(); 

    return () => {
      isMounted = false;
    };
  }, []);

  if (!state.fetched) return <Loading />;

  if (state.fetched && !state.project) return  (<NotFound/>)
*/

  const [organization, project, respondents] = useOutletContext();

  return (
    <Fragment>

      <div className="project-fields">
        <div className="field-wrapper">
          <div class="field-title">Goal</div>
          <div class="field-text">
            <p dangerouslySetInnerHTML={{__html: project.goalText.replace(/(\n)+/g,"\n").replace(/\n/g,"<br/><br/>")}}></p>
          </div>
        </div>
        <div className="field-wrapper">
          <div class="field-title">Hypothesis</div>
          <div class="field-text">
            <p dangerouslySetInnerHTML={{__html: project.hypothesisText.replace(/(\n)+/g,"\n").replace(/\n/g,"<br/><br/>")}}></p>
          </div>
        </div>
        <div className="field-wrapper">
          <div class="field-title">Target Audience</div>
          <div class="field-text">
            <p dangerouslySetInnerHTML={{__html: project.segmentText.replace(/\n/g,"<br/>")}}></p>
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default ProjectOverview;
