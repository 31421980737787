import React, { useEffect, useState } from 'react';
import { getOrganizations, addUser } from '../../../services/gutcheck-admin-api.service';
import { useAuth0 } from "@auth0/auth0-react";
import Select from 'react-select'

const AddUserForm = ({ parentCallback }) => {

  const [organizations, setOrganizations] = useState();
  const [form, setForm] = useState({
    email: '',
    role: '',
    organizations: null
  });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getOrganizations(accessToken);

      if (data ) {
        setOrganizations(data.organizations.map( organization => {
          return { value: organization._id, label: organization.name };
        }));
      }
    };

    fetchData();

    return () => {
    }
  }, []);


  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.id]: event.target.value,
    });
  };

  const handleRoleChange = (event) => {
    setForm({
      ...form,
      role: event
    });

  };

  const handleOrganizationsChange = (event) => {
    setForm({
      ...form,
      organizations: event
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const accessToken = await getAccessTokenSilently();
    const user = {
      emailAddress: form.email,
      role: form.role.value,
      organizations: form.organizations.map( organization => { return organization.value; })
    }
    const { data, error } = await addUser(user, accessToken);

    setForm({
      email: '',
      role: '',
      organizations: null
    });

    parentCallback( data.user );

  };

  const userRoles = [
    { value: "user", label: "user" },
    { value: "admin", label: "admin" }
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="admin-add-field email-wrapper">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="text"
          value={form.email}
          onChange={handleChange}
        />
      </div>
      <div className="admin-add-field role-wrapper">
        <label htmlFor="roles">Role</label>
        <Select
          inputId="role"
          name="role"
          inputProps={{ id: "role" }}
          options={userRoles}
          value={form.role}
          onChange={handleRoleChange}
        />
      </div>
      <div className="admin-add-field organizations-wrapper">
        <label htmlFor="organizations">Organizations</label>
        <Select
          isMulti
          className="basic-multi-select"
          classNamePrefix="select"
          name="organizations"
          inputProps={{ id: "organizations" }}
          autoSize={false}
          options={organizations}
          value={form.organizations}
          onChange={handleOrganizationsChange}
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export { AddUserForm };