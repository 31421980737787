import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import { useAuth0 } from "@auth0/auth0-react";


const EmailVerification = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  
  const logoutWithRedirect = () =>
    logout({
        logoutParams: {
          returnTo: window.location.origin,
        }
    });
  
  return (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
    <h1 className="mb-4">Almost there...</h1>
    <h3>Verify your email to continue.</h3>
    <p>Please check your {user.email} inbox for an email from GutCheck and click the link to verify your email addresss</p>
  </div>
)};

export default EmailVerification;