import React, { useState, useEffect, Fragment } from "react";
import { Table } from "reactstrap";
import { useParams, Link, Outlet, NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import NotFound from "../components/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProjectData } from "../services/gutcheck-api.service";

export const ProjectComponent = () => {

  const [state, setState] = useState({
    fetched: false,
    organization: null,
    project: null,
    respondents: null,
    error: null,
    ok: null
  });

  const { getAccessTokenSilently } = useAuth0();

  const { projectId } = useParams();

  useEffect(() => {
    let isMounted = true;

    const fetchProjectData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getProjectData(projectId, accessToken);

      if (!isMounted) {
        return;
      }

      if (data) {
        setState({
          ...state,
          fetched: true,
          organization: data.organization,
          project: data.project,
          respondents: data.respondents,
          error: error,
          ok: data.ok,
        });
      }
    };

    fetchProjectData();

    return () => {
      isMounted = false;
    };
  }, []);

  if (!state.fetched) return <Loading />;

  if (state.fetched && !state.project) return (<NotFound />)

  const project = state.project;
  const organization = state.organization;
  const respondents = state.respondents;
  const projectName = state.project.name;
  const projectTag = state.project.tag;

  return (
    <div class="gc_view">
      <div className="project-header">
        <h2>{projectName}</h2>

        { project.hypothesisText
          ? <NavLink to={`/project/${state.project.tag}/`}>Overview</NavLink>
          : <span class="disabled-navlink">Overview</span>
        }

        { project.surveyUrl
          ? <NavLink to={`/project/${state.project.tag}/survey`}>Ask</NavLink>
          : <span class="disabled-navlink">Ask</span>
        }
        
        { respondents.length > 0
          ? <NavLink to={`/project/${state.project.tag}/responses`}>Answer</NavLink>
          : <span class="disabled-navlink">Answer</span>
        }

        { project.reportUrl
          ? <NavLink to={`/project/${state.project.tag}/report`}>Analyze</NavLink>
          : <span class="disabled-navlink">Analyze</span>
        }

        <span class="disabled-navlink">Act</span>
      </div>
      <div className="project-content">
        <Outlet context={[ organization, project, respondents ]}/>

      </div>
    </div>
  );
};

export default ProjectComponent;
