import React, { Fragment, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import UserNotFound from "../components/UserNotFound";
import ProjectCard from "../components/ProjectCard";
import { getAllDataForSelf } from "../services/gutcheck-api.service";
import { useNavigate } from 'react-router-dom';


export const DashboardComponent = () => {

  const [state, setState] = useState({
    fetched: false,
    user: null,
    error: null,
    ok: null
  });

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchProjectData = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data, error } = await getAllDataForSelf(accessToken);

      if (!isMounted) {
        return;
      }

      if (data) {
        setState({
          ...state,
          fetched: true,
          user: data.user,
          error: error,
          ok: data.ok,
        });
      }
    };

    fetchProjectData(); 

    return () => {
      isMounted = false;
    };
  }, []);
  

  if (!state.fetched) return <Loading />

  if (state.fetched && !state.user) return <UserNotFound />

  if (state.user.organizations.length == 1 && state.user.organizations[0].projects.length == 1) {
    navigate(`/project/${state.user.organizations[0].projects[0].tag}/responses`);
  }

  const projectList = state.user.organizations.map(organization => 
        <ProjectCard
          organization={organization}
          projects={organization.projects}
        />  
  );

  return (
      <Fragment>
        <h2 className="dashboard-title">Your Projects</h2>
          {projectList}
      </Fragment>
  )

}

export default DashboardComponent;
  
